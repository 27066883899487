@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    font-family: "Poppins", sans-serif !important;
}

:root {
    --blue-bg-color: rgb(22, 49, 131);
    --font-color: black;
    --dark-font-color: #eeeeee;
    --dark-bg-color: #000;
    --light-bg-color : #eee;
    --foreground-color: #e2e2e2;
    --font-family: "Poppins", sans-serif;
    --font-weight: bold;
    --button-color: #0077ff;
    --button-hover-color: #0061cf;
    --input-color: #fff;
    --heading-color: #fff;
    --tagline-color: #fff;
    --anchortag-color: #003c81;
    --form-bg-color: #79c3ffd2;
    --counter-color: rgb(5, 72, 255);
    --feature-box-color: #ffffff;
    --feature-section-bg-color: #ececec;
    --h1-color: #000;
    --h2-color: #5e5e5e;
    --dark-h2-color: #acacac;
    --h3-color: #313131;
    --dark-h3-color:#9a9a9a;
    --h4-color: #000;
    --dark-h4-color: #e6e6e6;
    --p-color: #000;
    --dark-p-color: #eaeaea;
    --price-color: rgb(2, 170, 2);
}

.scrollbar-hide::-webkit-scrollbar{
    display: none;
}
